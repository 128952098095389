import { Routes } from '@angular/router';
import { LanguageGuard } from './core/guard/language.guard';
import { BaseLayoutComponent } from './core/layout/base-layout/base-layout.component';

export const routes: Routes = [{
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
}, {
    path: ':language',
    canActivate: [LanguageGuard],
    component: BaseLayoutComponent,
    children: [
        {path: 'home', loadComponent: () => import('./page/home/home.component').then(c => c.HomeComponent)},
        {path: 'news', loadComponent: () => import('./page/news/news.component').then(c => c.NewsComponent)},
        {path: 'search', loadComponent: () => import('./page/search/search.component').then(c => c.SearchComponent)},
        {path: '**', loadComponent: () => import('./page/home/home.component').then(c => c.HomeComponent)},
    ]
}];
