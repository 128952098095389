import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { ShareService } from '../service/share.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate {
  private defaultLanguage = 'vi';
  private localStorageKey = 'lang';
  private supportedLanguages = ['vi', 'en']; // Add your supported languages here

  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private shareService: ShareService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const language = route.params['language'];

    if (!this.supportedLanguages.includes(language)) {
      if (isPlatformBrowser(this.platformId)) {
        const storedLanguage = localStorage.getItem(this.localStorageKey) || this.defaultLanguage;
        this.shareService.setLanguage(storedLanguage)
        const newUrl = this.router.createUrlTree([storedLanguage, ...state.url.split('/').slice(1)]);
        return newUrl;
      } else {
        // For SSR, fallback to default language
        this.shareService.setLanguage(this.defaultLanguage)
        const newUrl = this.router.createUrlTree([this.defaultLanguage, ...state.url.split('/').slice(1)]);
        return newUrl;
      }
    }

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.localStorageKey, language);
    }

    this.shareService.setLanguage(language);

    if (state.url.split('/').slice(1).toString().startsWith('#')) {
      const newUrl = this.router.createUrlTree([language, '/home']);
      return newUrl;
    }

    return true;
  }
}
