import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { ShareService } from '../service/share.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private shareService: ShareService, @Inject(PLATFORM_ID) private platformId: Object) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let language = this.shareService.getLanguageStr() || 'vi';; // Default language

    // if (isPlatformBrowser(this.platformId)) {
    //   language = this.shareService.getLanguageStr() || 'vi';
    // }

    const clonedRequest = req.clone({
      setHeaders: {
        'Accept-Language': language
      }
    });

    return next.handle(clonedRequest);
  }
}
