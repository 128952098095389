import { AfterViewInit, Component, DestroyRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoaderService } from '../../../shared/dynamic-component-loader/dynamic-component-loader.service';
import { ShareService } from '../../service/share.service';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-base-layout',
  standalone: true,
  imports: [],
  templateUrl: './base-layout.component.html',
  styleUrl: './base-layout.component.scss'
})
export class BaseLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('dynamicLayout', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  pathRouter: string = '';
  language: string = '';
  _loadComponent: any;
  private previousUrl: string = '';

  constructor(
    private loaderService: DynamicComponentLoaderService,
    private shareService: ShareService,
    private route: ActivatedRoute,
    private router: Router,
    private destroy: DestroyRef,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.route.paramMap.subscribe(res => {
      this.language = res.get('language') || 'vi';
      this.document.documentElement.lang = this.language;
    })

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        if (!this.previousUrl || !this.hasOnlyQueryParamsChanged(this.previousUrl, event.url)) {
          if (!event.url.includes('.css.map') && !event.url.includes('.js.map') && !event.url.includes('.jpg') && !event.url.includes('.png')) {
            this.pathRouter = event.url.split(`/`).pop() || '/';
            this.pathRouter = this.pathRouter.split('?').shift() || '/';
            // this.pathRouter = event.url === `/${this.language}/home` ? '/' : event.url.split(`/${this.language}`).pop() || '/';
            if (this.pathRouter.startsWith('#')) this.router.navigateByUrl('/home');
            if (this.pathRouter === this.language) this.router.navigateByUrl('/home');
            console.log(event.url)
            // this.container.clear();
            this.getData();
            this.previousUrl = event.url;
          }
        }
      });
  }

  ngOnInit() {
    this.shareService.getChangeLanguage().pipe(takeUntilDestroyed(this.destroy)).subscribe(
      res => {
        if (res) {
          let _language = this.language == 'vi' ? 'en' : 'vi';
          this.shareService.setLanguage(_language);
          localStorage.setItem('lang', _language);
          this.router.navigateByUrl(`/${_language}/home`, { replaceUrl: true });
          // window.location.reload()
        }
      }
    )
  }

  ngAfterViewInit() {
    // this.loadComponents();
  }

  private hasOnlyQueryParamsChanged(prevUrl: string, newUrl: string): boolean {
    const prevUrlParts = new URL(prevUrl, 'https://localhost:1234');
    const newUrlParts = new URL(newUrl, 'https://localhost:1234');

    return prevUrlParts.pathname === newUrlParts.pathname &&
      prevUrlParts.search !== newUrlParts.search;
  }


  getData() {
    this.shareService.getData(this.pathRouter).pipe(takeUntilDestroyed(this.destroy)).subscribe(
      (res: any) => {
        console.log(res)
        if (res.success) {
          this._loadComponent = [{ name: res.result?.pageInfo?.pageLayoutTemplateName }];
          let config: any = {};
          res.result?.listWidgetConfig.forEach((element: any) => {
            if (!config[element.pageWidgetConfig.pageWidgetConfig.areaCode]) config[element.pageWidgetConfig.pageWidgetConfig.areaCode] = {};
            if (!config[element.pageWidgetConfig.pageWidgetConfig.areaCode].loadComponent) config[element.pageWidgetConfig.pageWidgetConfig.areaCode].loadComponent = [];
            switch (element.pageWidgetConfig.widgetDto.widgetCode) {
              case 'DisplayNewsDetailComponent':
              case 'DetailNewsGalleryComponent':
              case 'HotNewsComponent':
              case 'PostedNewsTimelineComponent':
              case 'PostedNewsVideoComponent':
                config[element.pageWidgetConfig.pageWidgetConfig.areaCode].loadComponent.push({
                  name: element.pageWidgetConfig.widgetDto.widgetCode,
                  data: {
                    configText: JSON.parse(element.pageWidgetConfig.widgetDto.language),
                    data: {
                      ...element,
                      ...res.result.newsInfo,
                    }
                  }
                })
                break;

              case 'BreadcrumbComponent':
              case 'BannerWithTitleComponent':
                config[element.pageWidgetConfig.pageWidgetConfig.areaCode].loadComponent.push({
                  name: element.pageWidgetConfig.widgetDto.widgetCode,
                  data: {
                    data: {
                      ...element,
                      slug: this.pathRouter,
                    }
                  }
                })
                break;

              case 'NewsWithPagingComponent':
              case 'NewsWithListStyleComponent':
              case 'NewsWithTimelineComponent':
              case 'NewsWithStyle4colComponent':
                config[element.pageWidgetConfig.pageWidgetConfig.areaCode].loadComponent.push({
                  name: element.pageWidgetConfig.widgetDto.widgetCode,
                  data: {
                    data: {
                      ...element,
                      categoryInfos: { ...res.result.categoryInfo },
                    }
                  }
                })
                break;
              
                case 'NewsEventComponent':
                config[element.pageWidgetConfig.pageWidgetConfig.areaCode].loadComponent.push({
                  name: element.pageWidgetConfig.widgetDto.widgetCode,
                  data: {
                    data: {
                      ...element,
                      eventInfo: { ...res.result.eventInfo },
                    }
                  }
                })
                break;
              
              case 'HeaderComponent':
                config[element.pageWidgetConfig.pageWidgetConfig.areaCode].loadComponent.push({
                  name: element.pageWidgetConfig.widgetDto.widgetCode,
                  data: {
                    data: {
                      ...element,
                      activeRouter: this.pathRouter,
                    }
                  }
                })
                break;

              default:
                config[element.pageWidgetConfig.pageWidgetConfig.areaCode].loadComponent.push({
                  name: element.pageWidgetConfig.widgetDto.widgetCode,
                  data: {
                    configText: JSON.parse(element.pageWidgetConfig.widgetDto.language),
                    data: {
                      ...element
                    }
                  }
                })
                break;
            }
          });
          this.sortByOrder(config);
          if (res.result?.newsInfo != null && res.result?.newsInfo?.newsInfo?.meta != null) {
            this.setMetaTag(JSON.parse(res.result.newsInfo.newsInfo.meta))
          } else if (res.result?.eventInfo != null && res.result?.eventInfo?.meta != null) {
            this.setMetaTag(JSON.parse(res.result.eventInfo.meta))
          } else if (res.result?.categoryInfo != null && res.result?.categoryInfo?.meta != null) {
            this.setMetaTag(JSON.parse(res.result.categoryInfo.meta))
          } else {
            this.setMetaTag(JSON.parse(res.result.pageInfo.pageInfo.meta))
          }
        }
      }
    )
  }

  sortByOrder(data: any) {
    if (data) {
      Object.keys(data).forEach((key: any) => {
        if (data[key].loadComponent.length > 1) {
          data[key].loadComponent = data[key].loadComponent.sort((a: any, b: any) => { return a.data.data.pageWidgetConfig.pageWidgetConfig.order - b.data.data.pageWidgetConfig.pageWidgetConfig.order })
        }
      });
      this.loadComponents(data);
    }
  }

  async loadComponents(config?: any) {
    this.shareService.setDataShared({
      loadComponent: config
    })
    this.container.clear();
    for (const component of this._loadComponent) {
      await this.loaderService.loadComponent(this.container, component.name, component.data || null);
    }
  }

  setMetaTag(data: any) {
    data.forEach((e: any) => {
      this.shareService.updateMeta(e.keyName, e.content, e.keyProperty, e.keyItemprop);
      if (e.keyProperty === 'og:title') {
        this.titleService.setTitle(e.content || 'Trang thông tin Tổng Công ty Phát điện 1 - EVNGENCO1')
      }
    });
  }
}
