import {
    HttpRequest,
    HttpInterceptorFn,
    HttpHandlerFn,
    HttpEvent,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, finalize, tap } from 'rxjs';
// import { NgxSpinnerService } from 'ngx-spinner';

let pendingRequest: number = 0;
let showLoading: boolean = false;

export const RequestResponseHandlerInterceptor: HttpInterceptorFn = (
    request: HttpRequest<any>,
    next: HttpHandlerFn
):Observable<HttpEvent<any>> => {
    // const spinnerService = inject(NgxSpinnerService);
    const req = request.clone({
        url: `${environment.API_URL}/${request.url}`
    });

    // if (!request?.url?.includes('RefreshToken')) {
    //     pendingRequest++;

    //     // if (!this.showLoading) {
    //     //this.showLoading = true;
    //     spinnerService.show("sp3");
    //     // }
    //     // this.showLoading = true;

    //     return next(req).pipe(tap((res: any) => {
    //         // this.turnOffModal();
    //         // pendingRequest--;

    //         if (pendingRequest <= 0) {
    //             //this.loaderService.hide();
    //             // if (this.showLoading) {
    //             spinnerService.hide("sp3");
    //             // }
    //             // this.showLoading = false;
    //         }

    //     }, (err: any) => {
    //         // this.turnOffModal();
    //         // pendingRequest--;

    //         if (pendingRequest <= 0) {
    //             //this.loaderService.hide();
    //             // if (this.showLoading) {
    //             spinnerService.hide("sp3");
    //             // }
    //             // this.showLoading = false;
    //         }
    //     })).pipe(
    //         finalize(() => {
    //             // modalSpinner.turnOffModal();
    //             pendingRequest--;

    //         if (pendingRequest <= 0) {
    //             //this.loaderService.hide();
    //             // if (this.showLoading) {
    //             spinnerService.hide("sp3");
    //             // }
    //             // this.showLoading = false;
    //         }
    //         }))
    // } else {
        return next(req).pipe(tap((res: any) => {
        }, (err: any) => {
        }))
    // }
};
